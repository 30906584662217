import React from "react"
import styled from "styled-components"
import { rem } from "../../util/style"
import ArticleCard from "./ArticleCard"

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  margin: 0 auto;
  margin-top: ${rem(-192)};
  position: relative;
  max-width: ${rem(1300)};

  @media only screen and (max-width: 1279px) {
    padding: 0 3rem;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 1.5rem;
    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: center;
  }
`

const ArticlesGrid = ({ articles }) => {
  return (
    <CardContainer>
      {articles.map(article => (
        <ArticleCard key={article.slug} article={article} />
      ))}
    </CardContainer>
  )
}

export default ArticlesGrid
