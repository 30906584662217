import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Nav from "../components/Navigation"
import Text from "../components/Text"
import Footer from "../components/Footer"
import ArticlesGrid from "../components/Blog/ArticlesGrid"
import CtaBlock from "../components/Blog/CtaBlock"
import Pagination from "../components/Blog/Pagination"

import {
  Main,
  Header,
  HeaderContent,
  PaddingContainer,
  FooterBG,
} from "../style/blog"

export const pageQuery = graphql`
  query blogsQuery($skip: Int!, $limit: Int!) {
    allStrapiBlog(
      limit: $limit
      skip: $skip
      sort: { fields: PublishDate, order: DESC }
    ) {
      nodes {
        ...ArticleCard
      }
    }
    strapiBlogListing {
      Title
      Subtitle
      ArticlesPerPage
      CallToAction {
        strapi_id
        Title
        Subtitle
      }
    }
  }
`
const BlogListTemplate = params => {
  const { pageContext, data } = params
  const { currentPage, totalPages } = pageContext
  const { allStrapiBlog, strapiBlogListing } = data

  return (
    <Main>
      <Helmet
        bodyAttributes={{ class: "blog" }}
        htmlAttributes={{ class: "blog" }}
      />
      <Nav />
      <Header>
        <HeaderContent>
          <Text size="h1" mb="8">
            {strapiBlogListing.Title}
          </Text>
          <Text size="body" color="#9EBCF8" mb="64" width="672" align="center">
            {strapiBlogListing.Subtitle}
          </Text>
        </HeaderContent>
      </Header>
      <ArticlesGrid articles={allStrapiBlog.nodes} />
      <PaddingContainer>
        {totalPages > 1 && <Pagination {...{ currentPage, totalPages }} />}
        <CtaBlock content={strapiBlogListing.CallToAction[0]} />
      </PaddingContainer>

      <FooterBG>
        <Footer small />
      </FooterBG>
    </Main>
  )
}

export default BlogListTemplate
