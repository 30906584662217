import React from "react"
import moment from "moment"
import styled from "styled-components"
import { Fonts, Weight } from "../../style/fonts"
import { rem } from "../../util/style"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Text from "../Text"

const Card = styled(props => <Link {...props} />)`
  max-width: 413px;
  text-decoration: none;

  flex: 1 1 28%;
  margin: 0 ${rem(10)};
  margin-bottom: ${rem(48)};
  background: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;

  @media only screen and (max-width: 1023px) {
    flex: 1 1 45%;
  }
`

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ImageWrap = styled.div`
  flex: 0 0 auto;
`

const Content = styled.div`
  flex: 1 1 auto;

  padding: ${rem(24)};

  display: flex;
  flex-direction: column;
`

const DescriptionBlock = styled.div`
  margin: 0;
  color: #6b7280;
  font-size: ${rem(16)};
  font-family: ${Fonts.inter};
  font-weight: ${Weight.normal};
  line-height: ${rem(24)};

  strong {
    color: #000;
  }
`

const Author = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${rem(24)};
  padding-top: 0;
`

const AuthorImage = styled.div`
  flex: 0 0 auto;
  margin-right: ${rem(12)};
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: 50%;
  overflow: hidden;
`

const AuthorContent = styled.div`
  flex: 1 1 auto;
`

const AuthorInitials = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};

  color: white;
  font-size: ${rem(14)};

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgb(6, 18, 44);
`

function formatDate(str) {
  return moment(str).format("MMM D, YYYY")
}

function initials(name) {
  const words = name.split(" ")
  const initials = words.map(word => word[0].toUpperCase()).join("")
  return initials
}

const ArticleCard = ({ article }) => {
  return (
    <Card to={`/blog/${article.slug}`}>
      <CardWrap>
        <ImageWrap>
          <GatsbyImage
            image={getImage(article.Picture?.localFile)}
            alt={article.Picture?.alternativeText}
          />
        </ImageWrap>
        <Content>
          <Text size="blogType" color="#1343FB" mb="8">
            {article.ArticleType}
          </Text>
          <Text size="blogTitle" color="#06122C" mb="12">
            {article.Title}
          </Text>
          <DescriptionBlock>{article.Description}</DescriptionBlock>
        </Content>
        <Author>
          {article.Author && (
            <AuthorImage>
              {article.Author?.Picture ? (
                <GatsbyImage
                  image={getImage(article.Author?.Picture?.localFile)}
                  alt={article.Author?.Picture?.alternativeText}
                />
              ) : (
                <AuthorInitials>
                  {initials(article.Author?.Name)}
                </AuthorInitials>
              )}
            </AuthorImage>
          )}

          <AuthorContent>
            {article.Author?.Name && (
              <Text size="author" color="#06122C">
                {article.Author?.Name}
              </Text>
            )}
            <Text size="date" color="#6B7280">
              {formatDate(article.PublishDate)}
              {article.ReadEstimate && <> · {article.ReadEstimate} read</>}
            </Text>
          </AuthorContent>
        </Author>
      </CardWrap>
    </Card>
  )
}

export const query = graphql`
  fragment ArticleCard on STRAPI_BLOG {
    id
    slug
    Title
    ArticleType
    Description
    Picture {
      localFile {
        url
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    PublishDate
    ReadEstimate
    Author {
      Name
      Picture {
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default ArticleCard
