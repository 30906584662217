import React from "react"
import styled from "styled-components"
import { rem } from "../../util/style"
import Text from "../Text"
import EmailForm from "../Header/EmailForm"

const Block = styled.div`
  padding: ${rem(64)};

  background: #06122c;
  border-radius: 8px;

  display: flex;
  flex-direction: row;

  max-width: ${rem(1216)};
  margin: ${rem(64)} auto;

  @media only screen and (max-width: 1023px) {
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    padding: ${rem(42)};
    padding-bottom: ${rem(0)};
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const TextContent = styled.div`
  flex: 1 1 auto;
`

const FormContent = styled.div`
  flex: 0 0 auto;

  @media only screen and (max-width: 767px) {
    margin-top: ${rem(24)};
    width: 100%;
    flex: 1 1 auto;
  }
`

const CtaBlock = ({ content }) => {
  return (
    <Block>
      <TextContent>
        <Text size="ctaTitle" color="#fff">
          {content.Title}
        </Text>
        <Text size="ctaSubheader" color="#9EBCF8">
          {content.Subtitle}
        </Text>
      </TextContent>
      <FormContent>
        <EmailForm nomargin />
      </FormContent>
    </Block>
  )
}

export default CtaBlock
