import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Fonts, Weight } from "../../style/fonts"
import { rem } from "../../util/style"
import Text from "../Text"
import ForwardIcon from "../../svgs/forward-icon.svg"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-top: 1px solid #e5e7eb;
  max-width: ${rem(1280)};
  margin: 0 auto;
`

const Icon = styled.div`
  ${props =>
    props.prev ? `margin-right: ${rem(14)}` : `margin-left: ${rem(14)}`};
  transform: ${props => (props.prev ? `scaleX(-1)` : "none")};
`

const PaginationElement = styled.div`
  display: flex;
  flex-direction: row;
`
const Button = styled(props => <Link {...props} />)`
  padding: ${rem(17)} 0;

  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
`

const PageLinkBlock = styled.div`
  padding: ${rem(17)} 0;
  width: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => (props.current ? `border-top: 2px solid #1343FB` : ``)}
`
const PageLink = styled(props => <Link {...props} />)`
  color: ${props => (props.current ? `#1343FB` : `#6B7280`)};
  font-size: ${rem(14)};
  font-family: ${Fonts.inter};
  font-weight: ${Weight.mid};
  line-height: ${rem(20)};

  text-decoration: none;
  text-align: center;
`

const Pagination = ({ currentPage, totalPages }) => {
  const showNext = currentPage < totalPages
  const showPrev = currentPage > 1
  return (
    <Container>
      <PaginationElement>
        {showPrev && (
          <Button to={currentPage === 2 ? `/blog` : `/blog/${currentPage - 1}`}>
            <Icon prev>
              <ForwardIcon />
            </Icon>
            <Text size="pagination" color="#6B7280">
              Previous
            </Text>
          </Button>
        )}
      </PaginationElement>

      <PaginationElement>
        {Array.from({ length: totalPages }).map((_, i) => (
          <PageLinkBlock current={currentPage === i + 1}>
            <PageLink
              to={i === 0 ? `/blog` : `/blog/${i + i}`}
              current={currentPage === i + 1}
            >
              {i + 1}
            </PageLink>
          </PageLinkBlock>
        ))}
      </PaginationElement>

      <PaginationElement>
        {showNext && (
          <Button to={`/blog/${currentPage + 1}`}>
            <Text size="pagination" color="#6B7280">
              Next
            </Text>
            <Icon>
              <ForwardIcon />
            </Icon>
          </Button>
        )}
      </PaginationElement>
    </Container>
  )
}

export default Pagination
